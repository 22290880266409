.settings .card {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  border-radius: 5px;
  border: none;
  margin-bottom: 15px;
}

.settings-nav {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  border-radius: 5px;
}
.settings-nav .nav-item {
  width: 100%;
}
.settings-nav .nav-link {
  border-radius: 0;
  text-transform: uppercase;
  color: #18214d;
  font-weight: 600;
  margin: 2px 0;
  padding: 10px 20px;
}
.settings-nav .nav-link i {
  margin-right: 10px;
}
.wallet .settings-nav .nav-link i {
  margin-right: 5px;
}
.settings .card-title {
  font-size: 16px;
  margin-bottom: 30px;
}

.settings-profile .col-md-6 {
  margin-bottom: 20px;
}

.settings-profile input,
.settings-profile select {
  border: 1px solid #e0e3eb;
  border-radius: 5px;
  font-size: 14px;
  height: 45px;
}

.settings-profile input[type="submit"] {
  background: #007bff;
  color: #fff;
  font-weight: 600;
  padding: 10px 25px;
  height: inherit;
  font-size: 16px;
  margin-top: 10px;
}

.settings-profile .custom-file {
  display: inline-block;
  width: 20%;
  margin-left: 20px;
}

.settings-profile .custom-file label {
  border: 1px solid #e0e3eb;
}

.settings-profile .custom-file label:after {
  background: #007bff;
  color: #fff;
  font-weight: 600;
}

.settings .wallet .nav-pills img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.settings .wallet .nav-pills h2 {
  margin-bottom: 0;
  line-height: 1;
  color: #18214d;
  font-size: 22px;
}

.settings .wallet .nav-pills p {
  margin-bottom: 0;
  color: #18214d;
  font-weight: 400;
  text-transform: capitalize;
}

.settings .wallet .nav-pills a.active {
  background: #0f7dff;
}
.settings .wallet .nav-pills a {
  border-top: 1px solid #f0f3fa;
  padding: 15px;
}
.settings .wallet .nav-pills a:first-child,
.settings-nav .nav-link:first-child {
  border-top: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.settings .wallet .nav-pills a:last-child,
.settings .settings-nav .nav-link:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.settings .wallet .nav-pills h3 {
  color: #18214d;
  margin-bottom: 0;
}
.settings .wallet .nav-pills {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 14px -6px;
  border-radius: 5px;
}
.settings .wallet .nav-pills a {
  border-radius: 0;
}
.settings .wallet .nav-pills a.active h2,
.settings .wallet .nav-pills a.active h3,
.settings .wallet .nav-pills a.active p {
  color: #ffffff;
}

.settings .wallet .tab-content li i {
  font-size: 22px;
  color: #0f7dff;
  background: rgba(15, 125, 255, 0.13);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 45px;
  margin-right: 15px;
}

.settings .wallet .tab-content h2 {
  margin-bottom: 0;
  font-size: 18px;
}
.settings .wallet .tab-content h3 {
  margin-bottom: 0;
  font-size: 18px;
}

.settings .wallet .tab-content .balance li:first-child {
  border-bottom: 1px solid #f0f3fa;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.button-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
button.transaction {
  color: #ffffff;
  padding: 12px 60px;
}
button.deposit-btn {
  background: #26de81;
  box-shadow: 0px 0px 15px 0px #26de81ad;
}
button.withdraw-btn {
  background: #ff231f;
  box-shadow: 0px 0px 15px 0px #ff231fad;
}
.settings .wallet .tab-content button.green {
  background: #26de81;
  color: #ffffff;
  box-shadow: 0px 0px 15px 0px #26de81ad;
}
.settings .wallet .tab-content button.red {
  background: #ff231f;
  box-shadow: 0px 0px 15px 0px #ff231fad;
  color: #ffffff;
}
.settings .wallet .tab-content button {
  padding: 12px 60px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.settings .wallet .tab-content button:focus {
  box-shadow: none;
}
.settings .wallet .tab-content ul {
  margin: 0 0 40px;
}

.settings .wallet-address input {
  padding: 25px 15px;
  font-size: 14px;
}
.settings .wallet-address .input-group-prepend button {
  padding: 13px 20px;
  width: 110px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.settings .wallet-address input {
  border: 1px solid #e0e3eb;
}
.settings .wallet-address ol {
  color: #e0e3eb;
  list-style: decimal;
  margin-left: 1rem;
  li {
    padding-left: 1rem;
    border: none;
  }
}
.settings .wallet-history i {
  margin-left: 14px;
}

.settings .wallet-address .input-group {
  padding-top: 30px;
}
.settings .wallet .tab-content h2.wallet-title {
  color: #18214d;
  font-size: 16px;
}

.settings-notification li {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f0f3fa;
  align-items: center;
}
.settings-notification li:first-child {
  padding-top: 0;
}

.settings-notification li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.settings-notification li span {
  color: #18214d59;
}

.settings-notification li p {
  margin-bottom: 0;
}

.settings .custom-control-label {
  cursor: pointer;
}

.wallet-history .ion-md-trash {
  font-size: 16px;
  cursor: pointer;
}
.wallet-history {
  overflow-x: auto;
}
