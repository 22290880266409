.market-page {
  background-color: #1b1f2f;
}
.market-bet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  .row {
    background-color: #272d43;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.account-balance {
  width: 90%;
  display: flex;
  justify-content: space-between;
  color: #d0d2d6;
  h3 {
    font-size: 18px;
    padding-top: 13px;
  }
}

.betting-section {
  background-color: #272d43;
  min-height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  padding-block: 1rem;
  border-block: 1px solid #615f6b;
  align-items: center;
  flex-wrap: nowrap;
  .bet {
    display: flex;
    justify-content: space-around;
    width: 100%;
    row-gap: 1rem;
    flex-wrap: wrap-reverse;
  }
  .right {
    flex-wrap: wrap;
  }

  .img-wrapper {
    object-fit: cover;
    padding: 0;
    img {
      padding: 0;
      height: 80px;
      aspect-ratio: 2.5;
    }
  }
  .input-area {
    border: 1px solid #615f6b;
    border-radius: 5px;
    min-width: 150px;
    height: 80px;
    aspect-ratio: 2.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    grid-column: 1rem;
    .input-title {
      transform: translateY(-10px);
      background-color: #272d43;
      color: #8fa5bf;
      padding-inline: 10px;
    }
    .input-data {
      font-size: 34px;
      text-transform: uppercase;
      transform: translateY(-15px);
    }
    .input-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 54px;
      border-top: 1px solid #615f6b;
      transform: translateY(-15px);
      text-align: center;
      span {
        text-align: center;
        width: 100%;
        svg {
          transform: translateY(1px);
        }
      }
      .plus,
      .minus {
        cursor: pointer;
      }
      .curr {
        border-inline: 1px solid #615f6b;
      }
    }
  }
}
.multiple-timers {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cooldown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #d0d2d6;
  text-align: left;

  width: 100%;
  .blockContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    row-gap: 1rem;
    text-align: center;
  }
  .block {
    width: 200px;
  }
  .active-timer {
    h3 {
      font-size: 20px;
    }
  }
  .type-price {
    h3 {
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}
