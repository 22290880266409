@media only screen and (max-width: 1434px) {
  .market-pairs tbody {
    height: 760px;
  }
  .no-fluid .market-pairs tbody {
    height: 815px;
  }
  .settings-profile .custom-file {
    width: 25%;
  }
}
@media only screen and (max-width: 1300px) {
  .market-pairs tbody {
    height: 740px;
  }
  .no-fluid .market-pairs tbody {
    height: 755px;
  }
}
@media only screen and (max-width: 1200px) {
  .markets-capital-item {
    height: 289px;
  }
}
@media only screen and (max-width: 991px) {
  .error-page h2 {
    font-size: 140px;
  }
  .settings-nav {
    margin-bottom: 15px;
  }
  .settings-profile .custom-file {
    width: 35%;
  }
  .btn-1,
  .btn-2 {
    margin: 8px;
    text-align: center;
  }
}
@media only screen and (max-width: 918px) {
  .market-history tbody {
    height: 274px;
  }
  .account-balance {
    width: 80%;
  }
}
@media only screen and (max-width: 792px) {
  .market-pairs tbody {
    height: 699px;
  }
}
@media only screen and (max-width: 802px) {
  .cooldown {
    .empty-block {
      display: none;
    }
  }
}
@media only screen and (max-width: 768px) {
  .settings-profile .custom-file {
    width: 45%;
  }
  .account-balance {
    width: 86%;
  }

  header button.navbar-toggler i {
    font-size: 30px;
    color: #4a4a4a;
  }
  header button.navbar-toggler:focus {
    outline: none;
  }
  .navbar-collapse .dropdown.menu-avatar-icon {
    margin-top: 10px;
  }
  .no-fluid .market-pairs {
    margin-bottom: 15px;
  }
  .no-fluid .order-book {
    margin-top: 15px;
  }
  .circle-pulse {
    left: 25px;
    right: inherit;
    top: 48px;
  }
  .header-img-icon {
    margin-top: 10px;
  }
  .header-custom-icon button {
    display: block;
  }
  .dropdown-menu::before {
    right: inherit;
    left: 5%;
  }
  .wallet .nav {
    margin-bottom: 30px;
  }
  .wallet-address img {
    margin-top: 40px;
  }
  .dark header button.navbar-toggler i {
    color: #c5cbce;
  }
  .dark .landing-info-one-bg {
    background: none;
  }

  .market-order-item {
    padding: 5px 5px;
    overflow: hidden;
  }
  .market-order-item li {
    font-size: 12px;
  }
  .markets-capital-item {
    height: 304px;
  }
}
@media only screen and (max-width: 576px) {
  .markets-capital-item {
    height: 91.4%;
  }
  .account-balance {
    width: 96%;
  }
}

@media only screen and (max-width: 440px) {
  .betting-section {
    .input-area {
      height: 80px;
      aspect-ratio: 1.5;
    }
    .img-wrapper {
      img {
        height: 60px;
      }
    }
  }
}
