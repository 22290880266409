.main-chart {
  height: 370px;
  h1 {
    color: #d9d9d9;
    text-align: center;
    background-color: #272d43;
    font-size: 26px;
    padding-block: 3px;
    margin-block: 0px;
  }
}
.technical-analysis .tradingview-widget-container {
  margin-bottom: 30px;
}
.symbol-info .tradingview-widget-container {
  margin-bottom: 15px;
}
.markets-overview .tradingview-widget-container {
  margin-bottom: 30px;
}
