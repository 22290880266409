.trades-log {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-top: 10px;
  border-top: 2px solid #615f6b;
  width: 100%;
  h3 {
    font-size: 22px;
  }
  .table-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  table {
    thead {
      th {
        color: #d9d9d9;
        background-color: #343d55;
        text-align: center;
      }
      border-bottom: 2px solid white;
    }
    tbody {
      td {
        color: #bfbfbf;
        background-color: #283046;
        border-bottom: 1px solid #615f6b;
        text-align: center;
      }
      span {
        color: white;
        padding: 5px 10px;
        border-radius: 3px;
      }
      .neg {
        background-color: #ea5455;
      }
      .pos {
        background-color: #28c76f;
      }
      .draw {
        background-color: #ff9f43;
      }
    }
  }
}
